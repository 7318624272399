import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { linkResolver } from 'helpers/LinkResolver'
import Layout from 'components/Layout';
import Seo from 'components/Seo'
import SliceZone from 'components/SliceZone';
import { ThemeProvider } from "styled-components";
import theme from 'styles/theme';

const PageTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicPage
  console.log(doc)
  return (
    // <ThemeProvider theme={theme}>
      <Layout>
        <Seo title={"page.data.document_display_name.text"} />
        <SliceZone sliceZone={doc.data.body} />
      </Layout>
    // </ThemeProvider>
  )
}

// export const query = graphql`
//   query PageQuery($id: String) {
//     prismicPage(id: { eq: $id }) {
//       _previewable
//       uid
//       data {
//         body {
//           ... on PrismicSliceType {
//             slice_type
//           }
//           ...PageInfoFragment
//         }
//         seo_description {
//           text
//         }
//         seo_title {
//           text
//         }
//       }
//     }
//   }
// `

export default withPrismicPreview(PageTemplate)
